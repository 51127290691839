<template>
  <div class="card">
    <div class="grid">
      <div class="col-12 p-fluid mt-2 mb-2 md:mt-0">
        <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center ">
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <Chips v-model="filter" placeholder="Pesquisar" />
            <div class="p-fluid mt-2 md:w-10">
              <SelectButton v-model="filtroAtendimento" :options="filtrosAtendimentos" optionLabel="label"
                optionValue="value" @click="load" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 md:col-4 p-fluid grade-atendimento">
        <AppLoadingWrapper v-if="loading" />
        <DataTable 
          v-if="!loading" 
          class="grade-atendimento" 
          selectionMode="single" 
          :value="atendimentos"
          v-model:selection="atendimentoSelecionado" 
          dataKey="id" 
          removableSort 
          responsiveLayout="scroll"
          @rowSelect="selecao" :paginator="true" :rows="10">
          <Column field="funcionario.name" header="Funcionário" :sortable="true" />
          <Column field="statusAtendimento.id" header="Status" :sortable="true" :style="{ padding: '1rem 0.5rem' }">
            <template #body="slotProps">
              <Tag class="mr-2" :style="tagStatusStyle(slotProps.data)"
                :value="slotProps?.data?.statusAtendimento?.descricao">
              </Tag>
            </template>
          </Column>
          <Column :style="{ padding: '1rem 0.5rem' }">
            <template #body="slotProps">
              <Button style="color: #6c757d" type="button" icon="pi pi-ellipsis-v"
                class="p-button-rounded p-button-text" @click="toggle($event, slotProps)" aria-haspopup="true"
                aria-controls="overlay_menu" />
            </template>
          </Column>
        </DataTable>
        <Menu id="overlay_menu" ref="menu" :model="menuItems" :popup="true" />
      </div>
      <div class="col-12 md:col-8 p-fluid painel painel-2">
        <template v-if="!atendimentoSelecionado">
          <TabView ref="tabview">
            <TabPanel>
              <template #header>
                <span class="mr-2">Dashboard</span>
              </template>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span class="mr-2">Calendário</span>
              </template>
              <div class="formgrid grid">
                <div class="field col">
                  <label for="agendas">Agenda</label>
                  <Dropdown id="agendas" v-model="agendaSelecionada" :options="agendas" dataKey="id" optionLabel="nome"
                    placeholder="Selecione..." />
                </div>
                <div class="field col">
                  <label for="ambientes">Ambiente</label>
                  <MultiSelect v-model="ambienteSelecionado" :options="ambientes" dataKey="id" optionLabel="nome"
                    placeholder="Selecione..." v-on:before-hide="carregarAgendaAtendimentos()" />
                </div>
              </div>
              <template v-if="ambienteSelecionado">
                <div class="col-12">
                  <FullCalendar :options="calendarOptions" />
                </div>
              </template>

            </TabPanel>
            <TabPanel>
              <template #header>
                <span class="mr-2">Controle de Profissionais</span>
              </template>
              <DataTable :value="profissionais" class="p-datatable-sm" selectionMode="single" dataKey="id" removableSort
                responsiveLayout="scroll" :paginator="true" :rows="10">
                <Column field="nome" header="Profissionais" />
                <Column header="Exames Atendidos">
                  <template #body="slotProps">
                    <span :key="procedimento.id" v-for="procedimento in slotProps.data.procedimentos">
                      {{ procedimento.name }},
                    </span>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>

            <TabPanel>
              <template #header>
                <span class="mr-2">Pendências</span>
                <i v-if="atendimentosPendentes.length > 0" class="mr-1" style="font-size: 1.5rem" v-badge.warning></i>
              </template>
              <DataTable :value="atendimentosPendentes" class="p-datatable-sm" selectionMode="single" dataKey="id"
                removableSort responsiveLayout="scroll" :paginator="true" :rows="10">
                <Column field="agendamento.id" header="Agendamento" />
                <Column field="descricao" header="Descrição da Pendência" />
                <Column bodyClass="text-right">
                  <template #body="slotProps">
                    <Button icon="pi pi-stop" class="p-button-text p-button mt-2" v-tooltip="'Concluir Pendencia'"
                      @click="concluirPendencia(slotProps.data)" />
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span class="mr-2">Tela de Chamada</span>
              </template>
            </TabPanel>
          </TabView>
        </template>

        <template v-if="atendimentoSelecionado">
          <TabView ref="tabview">
            <TabPanel header="Resultados de Exames">
              <template v-if="pendencias.length">
                <div class="col-14">
                  <div class="field">
                    <Accordion :multiple="true">
                      <AccordionTab header="Pendências">
                        <DataTable class="p-datatable-sm" :value="pendencias" responsiveLayout="scroll" editMode="cell">
                          <Column field="descricao" header="Descrição"></Column>
                          <Column bodyClass="text-right">
                            <template #body="slotProps">
                              <Button icon="pi pi-stop" class="p-button-text p-button mt-2"
                                v-tooltip="'Concluir Pendencia'" @click="concluirPendencia(slotProps.data)" />
                            </template>
                          </Column>
                        </DataTable>
                      </AccordionTab>
                    </Accordion>
                  </div>
                </div>
              </template>

              <template v-if="pendenciasResolvidas.length">
                <div class="col-14">
                  <div class="field">
                    <Accordion :multiple="true">
                      <AccordionTab header="Pendencias resolvidas">
                        <DataTable class="p-datatable-sm" :value="pendenciasResolvidas" responsiveLayout="scroll"
                          editMode="cell">
                          <Column field="descricao" header="Descrição"></Column>
                          <Column bodyClass="text-right">
                            <template #body>
                              <Button icon="pi pi-check-square" class="p-button-text p-button mt-2"
                                v-tooltip="'Pendencia Concluida'" />
                            </template>
                          </Column>
                        </DataTable>
                      </AccordionTab>
                    </Accordion>
                  </div>
                </div>
              </template>
              <div class="grid">
                <div class="col-12 md:col-6">
                  <div class="field">
                    <label for="tipoExames">Tipo de Exame</label>
                    <Dropdown id="tipoExames" v-model="atendimentoSelecionado.tipoExame" :options="tipoExames"
                      :disabled="true" dataKey="id" optionLabel="descricao" placeholder="Escolha o Tipo de Exame" />
                  </div>
                </div>
              </div>
              <Button :disabled="isDisabled" icon="pi pi-plus" class="p-button mb-2" v-tooltip="'Incluir Procedimento'"
                @click="dialogNovoProcedimento()" />
              <DataTable class="p-datatable-sm" :value="atendimentoProcedimento" responsiveLayout="scroll"
                editMode="cell" v-model:expandedRows="expandedRows">
                <Column :expander="true" headerStyle="width: 3rem" />
                <Column field="procedimentos.name" header="Procedimento"></Column>
                <Column field="dataAgendamento" header="Data Agendamento">
                  <template #body="slotProps">
                    <Calendar :manualInput="true" :disabled="isDisabled" v-model="slotProps.data.dataAgendamento"
                      dateFormat="dd/mm/yy" mask="99/99/9999 00:00" :showTime="true" hourFormat="24" />
                  </template>
                </Column>
                <Column bodyClass="text-right">
                  <template #body="slotProps">
                    <Button icon="pi pi-trash" class="p-button-text p-button mt-2" :disabled="isDisabled"
                      v-tooltip="'Excluir Procedimento'" @click="confirmRemoveProcedimento($event, slotProps.data)" />
                    <Button style="color: #D18200" icon="pi pi-exclamation-triangle" class="p-button-text p-button mt-2"
                      :disabled="isDisabled" v-tooltip="'Adicionar Pendência'" @click="openDialogPendencia = true" />
                  </template>
                </Column>
                <template #expansion="slotProps">
                  <div class="p-panel p-component">
                    <div class="p-panel-header">
                      <span class="p-panel-title">Mais Informações</span>
                    </div>
                    <div class="p-toggleable-content">
                      <div class="p-panel-content">
                        <div class="col-12">
                          <div class="formgrid grid">
                            <div class="field col">
                              <label>Prestador</label>
                              <Dropdown :disabled="isDisabled" v-model="slotProps.data.agendamento.prestador"
                                :options="prestadores" dataKey="id" optionLabel="name" placeholder="Selecione..." />
                            </div>
                          </div>
                          <div class="formgrid grid">
                            <div class="field col">
                              <label>Recomendação</label>
                              <Textarea v-model="slotProps.data.recommendation" :disabled="isDisabled"
                                :autoResize="true" rows="2" />
                            </div>
                            <div class="field col">
                              <label>Resultado do Exame</label>
                              <Textarea v-model="slotProps.data.descResultado" :disabled="isDisabled" :autoResize="true"
                                rows="2" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

              </DataTable>
            </TabPanel>
          </TabView>
          <template v-if="openDialogPendencia">
            <Dialog v-model:visible="openDialogPendencia" class="p-fluid" :style="{ width: '450px' }"
              header="Confirmar Pendência" :draggable="false" :closable="false" :modal="true">
              <div class="field">
                <label for="descricao">Motivo</label>
                <Textarea type="text" v-model="atendimentoSelecionadoMenu.motivo"
                  :class="{ 'p-invalid': submitted && !atendimentoSelecionadoMenu.motivo }" />
                <small class="p-error" v-if="submitted && !atendimentoSelecionadoMenu.motivo">Campo obrigatório.</small>
              </div>
              <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
                <Button label="Confirmar" icon="pi pi-save" class="p-button-outlined p-button-success"
                  @click="addPendencia" />
              </template>
            </Dialog>
          </template>
          <template v-if="openDialogConfirmGiveUp">
            <Dialog v-model:visible="openDialogConfirmGiveUp" class="p-fluid" :style="{ width: '450px' }"
              header="Confirmar" :draggable="false" :closable="false" :modal="true">
              <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="atendimentoSelecionadoMenu">Ao confirmar a desistência o agendamento será cancelado. Deseja
                  confirmar esta ação?</span>
              </div>
              <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
                <Button label="Confirmar" icon="pi pi-check" class="p-button-outlined p-button-danger"
                  @click="statusDesistencia" />
              </template>
            </Dialog>
          </template>
          <template v-if="openDialogConfirmCancel">
            <Dialog v-model:visible="openDialogConfirmCancel" class="p-fluid" :style="{ width: '450px' }"
              header="Confirmar" :draggable="false" :closable="false" :modal="true">
              <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="atendimentoSelecionadoMenu">Tem certeza que deseja cancelar o agendamento do colaborador
                  <strong>{{ atendimentoSelecionadoMenu.funcionario.name }}</strong>?</span>
              </div>
              <div class="field mt-3">
                <label for="descricao">Motivo</label>
                <Textarea type="text" v-model="atendimentoSelecionadoMenu.motivo"
                  :class="{ 'p-invalid': submitted && !atendimentoSelecionadoMenu.motivo }" />
                <small class="p-error" v-if="submitted && !atendimentoSelecionadoMenu.motivo">Campo obrigatório.</small>
              </div>
              <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
                <Button label="Confirmar" icon="pi pi-check" class="p-button-outlined p-button-danger"
                  @click="statusCancelado" />
              </template>
            </Dialog>
          </template>
          <template v-if="openDialogNovoProcedimento">
            <Dialog v-model:visible="openDialogNovoProcedimento" :style="{ width: '450px' }"
              header="Adicionar novo exame">
              <div class="field">
                <label>Procedimento</label>
                <Dropdown class="w-full" v-model="novoAgendamentoProcedimento.procedimentos" :options="procedimentos"
                  optionLabel="name" placeholder="Selecione..." />
              </div>
              <div class="field">
                <label>Data do Procedimento</label>
                <Calendar class="w-full" v-model="novoAgendamentoProcedimento.dataAgendamento" dateFormat="dd/mm/yy"
                  mask="99/99/9999 00:00" :showTime="true" hourFormat="24" />
              </div>
              <div class="field">
                <label>Prestador</label>
                <Dropdown class="w-full" v-model="novoAgendamentoProcedimento.prestadores" :options="prestadores"
                  optionLabel="name" placeholder="Selecione..." />
              </div>
              <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
                <Button label="Adicionar" icon="pi pi-plus" class="p-button-outlined p-button-success"
                  @click="addNovoProcedimento(novoAgendamentoProcedimento)" />
              </template>
            </Dialog>
          </template>
          <template v-if="!isDisabled">
            <div class="grid">
              <div class="col-12">
                <span class="p-buttonset">
                  <Button :disabled="!atendimentoSelecionado || isDisabled" label="Concluir Alteração"
                    icon="pi pi-check" @click="concluirAlteracao(atendimentoSelecionado)" />
                </span>
              </div>
            </div>
          </template>
          <ConfirmPopup group="demo">
            <template #message="slotProps">
              <div class="flex p-4">
                <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                <p class="pl-2">{{ slotProps.message.message }}</p>
              </div>
            </template>
          </ConfirmPopup>

        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AreaMedicaService from "@/services/AreaMedicaService";
import BaseService from '@/services/BaseService';

import FullCalendar from '@fullcalendar/vue3'
import '@fullcalendar/core/vdom'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
import pt from '@fullcalendar/core/locales/pt';

import StatusAtendimento from '@/enums/StatusAtendimento';
import StatusAgendamento from "@/enums/StatusAgendamento";
import { getClientBase } from "@/services/http";

export default {
  components: { FullCalendar },
  data() {
    return {
      loading: false,
      enumStatusAtendimento: StatusAtendimento,
      enumStatusAgendamento: StatusAgendamento,
      filter: [],
      filtroAtendimento: 1,
      submitted: false,
      isDisabled: true,
      atendimentos: [],
      atendimentoSelecionado: null,
      atendimentoSelecionadoMenu: [],
      atendimentosPendentes: [],
      atendimentoStatus: [],
      procedimento: {},
      procedimentos: [],
      tipoExames: [],
      agendas: [],
      agendaSelecionada: null,
      ambientes: [],
      ambienteSelecionado: null,
      profissionais: [],
      prestadores: [],
      pendenciasResolvidas: [],
      pendencias: [],
      expandedRows: [],
      openDialogPendencia: false,
      openDialogRemoveProcedimento: false,
      openDialogNovoProcedimento: false,
      openDialogConfirmCancel: false,
      openDialogConfirmGiveUp: false,
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          center: 'title',
          left: 'prev,today,next',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        editable: false,
        selectable: true,
        selectMirror: false,
        dayMaxEvents: false,
        locale: pt,
        inicialDate: '2022-06-01',
        events: [],
        eventClick: (events) => this.agendaDetalhesAtendimento(events?.event?._def?.publicId),
      },
      atendimentoProcedimento: [{
        agendamento: {
          prestador: {}
        },
        procedimentos: { id: null },
        recommendation: null,
      }],
      novoAgendamentoProcedimento: {
        agendamento: {},
        procedimentos: {},
        dataAgendamento: null,
        prestadores: {}
      },
      formAtendimentoStatus: {
        descricao: null,
        userId: null,
        idAgendamento: null,
        statusAtendimento: {},
      },
      filtrosAtendimentos: [
        { label: 'Todos Atendimentos', value: 1 },
        { label: 'Atendimentos Locais', value: 2 },
      ],
      menuItems: [
        {
          label: "Alterar",
          icon: "pi pi-pencil",
          disabled: () => {
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.DESISTENCIA_DE_VAGA) return true;
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.CANCELADO) return true;
          },
          command: async () => {
            this.isDisabled = false;
          }
        },
        {
          label: "Adicionar Pendência",
          icon: "pi pi-exclamation-triangle",
          disabled: () => {
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.DESISTENCIA_DE_VAGA) return true;
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.CANCELADO) return true;
          },
          command: async () => {
            this.openDialogPendencia = true;
          }
        },
        {
          label: "CheckIn",
          icon: "pi pi-lock-open",
          disabled: () => {
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.DESISTENCIA_DE_VAGA) return true;
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.CANCELADO) return true;
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.CHECKIN_EFETUADO) return true;
          },
          command: async () => {
            this.statusCheckIn();
          }
        },
        {
          label: "CheckOut",
          icon: "pi pi-lock",
          disabled: () => {
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.DESISTENCIA_DE_VAGA) return true;
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.CANCELADO) return true;
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.CHECKOUT_FETUADO) return true;
          },
          command: async () => {

            this.statusCheckOut();
          }
        },
        {
          label: "Desistência",
          icon: "pi pi-thumbs-down",
          disabled: () => {
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.DESISTENCIA_DE_VAGA) return true;
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.CANCELADO) return true;
          },
          command: async () => {
            this.openDialogConfirmGiveUp = true;
          }
        },
        {
          label: "Cancelar",
          icon: "pi pi-times",
          disabled: () => {
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.DESISTENCIA_DE_VAGA) return true;
            if (this.atendimentoSelecionadoMenu.statusAtendimento.id === this.enumStatusAtendimento.CANCELADO) return true;
          },
          command: async () => {
            this.openDialogConfirmCancel = true;
          }
        },
      ],
    };
  },
  mounted() {
    this.$serviceAtendimento = new AreaMedicaService("/agendamentos");
    this.$serviceStatusAtendimentos = new AreaMedicaService("/atendimento-status");
    this.$serviceTipoExames = new AreaMedicaService("/tipo_exames");
    this.$serviceProcedimentos = new BaseService("/procedures");
    this.$serviceAgendas = new AreaMedicaService("/agendas");
    this.$serviceAmbiente = new AreaMedicaService("/ambientes");
    this.$servicePrestadores = new BaseService("/suppliers");
    // this.$serviceAtendimentoProcedimento = new AreaMedicaService('/agendamento_procedimento/agendamento');
    this.$serviceProfissionais = new AreaMedicaService("/profissional_medico");
    this.$serviceStatusAtendimento = new AreaMedicaService('/atendimento-status/atendimento');
    this.$serviceAlterarAtendimento = new AreaMedicaService("/atendimento-status/alterarAtendimento");
    this.$serviceAgendaAtendimentos = new AreaMedicaService('/agendamento_procedimento/filtroAmbiente');
    this.$servicePendencia = new AreaMedicaService('/agendamento_pendencia');
    this.$servicePendenciaResolvida = new AreaMedicaService('/agendamento_pendencia/resolvida');

    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.atendimentoSelecionado = null;
      this.filtroStatus = { status: this.enumStatusAgendamento.AGENDADO };

      const [
        atendimentos,
        tipoExames,
        agendas,
        ambiente,
        prestadores,
        procedimentos,
        profissionais,
        atendimentosPendentes
      ] = await Promise.all([
        this.$serviceAtendimento.findAll({ filtrosExtras: this.filtroStatus }),
        this.$serviceTipoExames.findAll({}),
        this.$serviceAgendas.findAll({}),
        this.$serviceAmbiente.findAll({}),
        this.$servicePrestadores.findAll({
          limit: this.perPage,
          page: this.page,
          filter: this.filter,
        }),
        this.$serviceProcedimentos.findAll({
          limit: this.perPage,
          page: this.page,
          filter: this.filter,
        }),
        this.$serviceProfissionais.findAll({}),
        this.$servicePendencia.findAll({})
      ])
      this.atendimentos = atendimentos.data.items;
      this.total = atendimentos.total;
      this.tipoExames = tipoExames.data;
      this.agendas = agendas.data;
      this.ambientes = ambiente.data;
      this.prestadores = prestadores.data.items;
      this.procedimentos = procedimentos.data.items;
      this.profissionais = profissionais.data;
      this.atendimentosPendentes = atendimentosPendentes.data;
      
      const calculoPaginacao = atendimentos.total / this.perPage;
      this.totalPage =
        calculoPaginacao === Math.floor(calculoPaginacao)
          ? calculoPaginacao
          : Math.floor(calculoPaginacao) + 1;
      
      this.loading = false;
    },
    
    async carregarAgendaAtendimentos() {

      this.calendarOptions.events = [];

      if (!this.ambienteSelecionado) return;
      if (!this.ambienteSelecionado.length) return;

      const ambientesSelecionados = this.ambienteSelecionado.map(id => {
        return id.id
      })

      const agendaAtendimentoFiltro = await this.$serviceAgendaAtendimentos.findAll({ filter: ambientesSelecionados })

      const dadosAgenda = agendaAtendimentoFiltro.data.map(data => {
        return {
          id: data.agendamento.id,
          title: data.recommendation,
          start: data.dataAgendamento,
          end: data.dataAgendamento
        }
      });

      this.calendarOptions = {
        ...this.calendarOptions,
        events: dadosAgenda,
      }
    },
    async agendaDetalhesAtendimento(idAgendamento) {

      const response = await this.$serviceAtendimento.findById(idAgendamento);
      this.atendimentoSelecionado = response.data;
      this.selecao(response);
    },
    async toggle($event, record) {
      this.atendimentoSelecionadoMenu = record.data;
      this.atendimentoSelecionado = record.data;
      this.selecao(record);
      this.$refs.menu.toggle($event);
    },
    tagStatusStyle(data) {
      if (data.statusAtendimento) {
        return {
          'background-color': `var(--${data.statusAtendimento.primevueColor})`,
          'color': `var(--${this.changeColorTone(data.statusAtendimento.primevueColor, 900)})`
        }
      }
    },
    changeColorTone(primevueColor, newTone) {
      if (!primevueColor) return;
      const [color, tone] = primevueColor.split('-');
      return color + '-' + (newTone || tone);
    },
    async selecao(event) {
      const pendencias = await this.$servicePendencia.findById(event.data.id);
      this.pendencias = pendencias.data;

      const pendenciasResolvidas = await this.$servicePendenciaResolvida.findById(event.data.id);
      this.pendenciasResolvidas = pendenciasResolvidas.data;

      this.ambienteSelecionado = null;
      this.isDisabled = true;
      // const { data } = await this.$serviceAtendimentoProcedimento.findById(event.data.id);
      const { data } = await getClientBase().get(`/agendamento_procedimento/agendamento/${event.data.id}`, {
        params: { origem: 'PAINELATENDIMENTO_INDEX_SELECAO' }
      });

      this.atendimentoProcedimento = data;
      this.atendimentoProcedimento.forEach((record) => {
        if (record.dataAgendamento) {
          record.dataAgendamento = new Date(record.dataAgendamento)
        }
      });
    },
    confirmRemoveProcedimento(event, procedimento) {
      this.$confirm.require({
        target: event.currentTarget,
        group: 'demo',
        message: 'Deseja mesmo excluir esse Procedimento?',
        icon: 'pi pi-question-circle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        accept: () => {
          this.procedimento = procedimento;
          this.atendimentoProcedimento = this.atendimentoProcedimento.filter(procedimento => procedimento.id !== this.procedimento.id);
        },
        reject: () => {
          this.closeDialog();
        }
      });
    },
    async addPendencia() {
      this.submitted = true;
      if (!this.atendimentoSelecionadoMenu.motivo) return;

      this.atendimentoSelecionadoMenu.statusAtendimento = { id: this.enumStatusAtendimento.PENDENCIA };
      try {
        this.formAtendimentoStatus = {
          descricao: 'Status do atendimento alterado para Pendente. Motivo: ' + this.atendimentoSelecionadoMenu.motivo,
          agendamento: this.atendimentoSelecionadoMenu,
          status: this.atendimentoSelecionadoMenu.statusAtendimento
        }

        await this.$serviceStatusAtendimentos.save(this.formAtendimentoStatus);

        this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso.', detail: 'Agendamento alterado para pendente.', life: 5000 });
        this.closeDialog();
        this.load();
      } catch (err) {
        this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 5000 });
        this.closeDialog();
        this.load();
      }

    },
    dialogNovoProcedimento() {
      this.openDialogNovoProcedimento = true;
    },
    addNovoProcedimento(novoAgendamentoProcedimento) {
      novoAgendamentoProcedimento.agendamento = this.atendimentoSelecionado;
      this.atendimentoProcedimento.push(novoAgendamentoProcedimento);
      this.closeDialog();
      this.novoAgendamentoProcedimento = {
        agendamento: {},
        procedimentos: {},
        dataAgendamento: null,
        prestadores: {}
      };
    },
    async statusDesistencia() {
      this.atendimentoSelecionadoMenu.statusAtendimento = { id: this.enumStatusAtendimento.DESISTENCIA_DE_VAGA };
      try {
        this.formAtendimentoStatus = {
          descricao: 'Status do atendimento alterado para Desistência. Motivo: ' + this.atendimentoSelecionadoMenu.motivo,
          agendamento: this.atendimentoSelecionadoMenu,
          status: this.atendimentoSelecionadoMenu.statusAtendimento
        }

        await this.$serviceStatusAtendimentos.save(this.formAtendimentoStatus);

        this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso.', detail: 'Agendamento alterado para pendente.', life: 5000 });
        this.closeDialog();
        this.load();
      } catch (err) {
        this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 5000 });
        this.closeDialog();
        this.load();
      }
    },
    async statusCancelado() {
      this.atendimentoSelecionadoMenu.statusAtendimento = { id: this.enumStatusAtendimento.CANCELADO };
      try {
        this.formAtendimentoStatus = {
          descricao: 'Status do atendimento alterado para Cancelado. Motivo: ' + this.atendimentoSelecionadoMenu.motivo,
          agendamento: this.atendimentoSelecionadoMenu,
          status: this.atendimentoSelecionadoMenu.statusAtendimento
        }

        await this.$serviceStatusAtendimentos.save(this.formAtendimentoStatus);

        this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso.', detail: 'Agendamento alterado para pendente.', life: 5000 });
        this.closeDialog();
        this.load();
      } catch (err) {
        this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 5000 });
        this.closeDialog();
        this.load();
      }
    },
    async statusCheckIn() {
      this.atendimentoSelecionadoMenu.statusAtendimento = { id: this.enumStatusAtendimento.CHECKIN_EFETUADO };
      try {
        this.formAtendimentoStatus = {
          descricao: 'Funcionario efetuou Check-in.',
          agendamento: this.atendimentoSelecionadoMenu,
          status: this.atendimentoSelecionadoMenu.statusAtendimento
        }

        await this.$serviceStatusAtendimentos.save(this.formAtendimentoStatus);

        this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso', detail: 'Agendamento alterado para cancelado.', life: 5000 });
        this.closeDialog();
        this.load();
      } catch (err) {
        this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 5000 });
        this.closeDialog();
        this.load();
      }
    },
    async statusCheckOut() {
      this.atendimentoSelecionadoMenu.statusAtendimento = { id: this.enumStatusAtendimento.CHECKOUT_FETUADO };
      try {
        this.formAtendimentoStatus = {
          descricao: 'Funcionario efetuou Check-out.',
          agendamento: this.atendimentoSelecionadoMenu,
          status: this.atendimentoSelecionadoMenu.statusAtendimento
        }

        await this.$serviceStatusAtendimentos.save(this.formAtendimentoStatus);

        this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso', detail: 'Agendamento alterado para cancelado.', life: 5000 });
        this.closeDialog();
        this.load();
      } catch (err) {
        this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 5000 });
        this.closeDialog();
        this.load();
      }
    },
    async concluirPendencia(pendencia) {
      await this.$servicePendencia.save(pendencia);
      this.load();
    },
    async concluirAlteracao(record) {
      try {
        this.formAtendimentoStatus = {
          descricao: 'Informações do agendamento foram modificadas',
          agendamento: record,
          status: record.statusAtendimento,
          procedimentos: this.atendimentoProcedimento
        }

        await this.$serviceAlterarAtendimento.save(this.formAtendimentoStatus);

        this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso', detail: 'Agendamento alterado para concluído.', life: 3000 });
        this.closeDialog();
        this.load();
      } catch (err) {
        this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 5000 });
        this.closeDialog();
        this.load();
      }
    },
    closeDialog() {
      this.openDialogPendencia = false;
      this.openDialogNovoProcedimento = false;
      this.openDialogRemoveProcedimento = false;
      this.openDialogConfirmCancel = false;
      this.openDialogConfirmGiveUp = false;
    }
  }
};
</script>
<style scoped lang="scss">
.p-tag {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: .3px;
}

.grade-atendimento {
  border: 1px solid #dee2e6;
}

.painel {
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-left: none;
}

.custom-error .p-tooltip-text {
  background-color: var(--pink-800);
  color: rgb(255, 255, 255);
}

.custom-error.p-tooltip-right .p-tooltip-arrow {
  border-right-color: var(--pink-800);
}

.pi-exclamation-triangle {
  color: rgb(223, 163, 0)
}
</style>